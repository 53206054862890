import React from "react";
import { useRoutes } from 'react-router-dom';
import Home from "./Components/Home";
import AboutUs from "./Components/AboutUs";
import OurMission from "./Components/OurMission";
import WhyChooseUs from "./Components/WhyChooseUs";
import OurTeam from "./Components/OurTeam";
import ContactUs from "./Components/ContactUs";
import Projects from "./Components/Projects";
import CaptivePowerPlant from "./Components/Services/CaptivePowerPlant";
import CriticalPiping from "./Components/Services/CriticalPiping";
import Fabrication from "./Components/Services/Fabrication";
import Infrastructure from "./Components/Services/Infrastructure";
import MaintenanceofPowerProjects from "./Components/Services/MaintenanceofPowerProjects";
import Overhauling from "./Components/Services/Overhauling";
import SupplyofMaterial from "./Components/Services/SupplyofMaterial";
import ThermalPowerProject from "./Components/Services/ThermalPowerProject";


export default function App() {
   let element = useRoutes([
      
      {path: '/', element: <Home />},
      {path: '/about-us', element: <AboutUs />},
      {path: '/our-mission', element: <OurMission />},
      {path: '/why-choose-us', element: <WhyChooseUs />},
      {path: '/our-team', element: <OurTeam />},
      {path: '/contact-us', element: <ContactUs />},
      {path: '/project', element: <Projects />},
      {path: '/captive-plant', element: <CaptivePowerPlant />},
      {path: '/critical-piping', element: <CriticalPiping />},
      {path: '/fabrication', element: <Fabrication />},
      {path: '/infrastructure', element: <Infrastructure />},
      {path: '/maintenance-power-projects', element: <MaintenanceofPowerProjects />},
      {path: '/overhauling', element: <Overhauling />},
      {path: '/supply-of-material', element: <SupplyofMaterial />},
      {path: '/thermal-power-project', element: <ThermalPowerProject />},

   ]);
   return element;
}


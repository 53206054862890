import React from "react";
import WorkingProgress from "./WorkingProgress";
export default function AboutUs(){
return (
<>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">About Us</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">About Us</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Gives The Best Engineering way</h2>
               <p class="pt-section-description">M/S Arvind Kumar Construction is headed by Mr Arvind Kumar, an experienced person, who has been in the fabrication and Erection industry for past 10 years and is the Proprietor of Arvind Kumar Construction.<br/></p>
               <p> Under his excellent and innovative leadership along with his perseverance, Arvind Kumar Construction is increasing its reach in the steel fabrication and erection industry exponentially. Though the roots of the company can be found to lead us to Arvind Kumar Construction deals in technically specialised and diversified structural area.<br/>Throughout his amazing journey from establishing Arvind Kumar Construction. in 2013 with around 5 employees to building a steel fabrication empire of this magnitude in a span of 7 years is the proof of his dedication and hard-work, which is why Arvind Kumar Construction is proud to be headed by him and definitely look forward to achieving great heights. Mr Arvind Kumar has always kept his employees ‘Welfare uppermost in mind second only to Customer Satisfaction.
               </p>
            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Operation Program</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Quality Assurance</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">We provide World Class Quality in Work</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Safety</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Human Resources Development Services</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Major Objectivities considered in preparation of schedule</span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/demo-8.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
   <section class="blog">
      <div class="container">
         <div class="row no-gutters">
            <div class="col-xl-12 text-center">
               <div class="pt-section-title-box">
                  <span class="pt-section-sub-title">What We Delivered</span>
                  <h2 class="pt-section-title">About Prayagraj Infra Engineering Pvt Ltd</h2>
               </div>
            </div>
            <div class="col-lg-8 mb-5 mb-lg-0">
               <div class="pt-blog-post mb-4">
                  <div class="pt-post-media">
                     <img class="img-fluid" src="images/blog/abhi_10.jpg" alt=""/>
                  </div>
                  <div class="pt-blog-contain">
                     <p>Established on 24th January 2013, AKC is a highly diversified, multidisciplinary contractor & best all type of heavy fabrication and erection in the industry. Our team has earned its place in today’s competitive market through our combination of core believes and superior services and quality end products. The individualized care and unwavering attention that we bring to all our projects are deciding factors in why our clients always choose us.<br/> <br/>AKCs crew of skilled and experienced professionals combined with our unparalleled dedication and complete client focus have been sure a way to make every project a success.<br/><br/> It has overcome many hurdles with a team of highly-experienced employees based out of Allahabad, India, working towards the sole vision of developing and maintaining a fabrication business that serve their clients with honesty and integrity keeping client’s interest ahead of the companies, so as to give them a quality and service which shall never be forgotten.</p>
                     <h4 class="mb-2 mt-4">Project Execution Schedule</h4>
                     <p>A suitable plan for each project in different approach to meet the requirement of individual project should conceived, developed and implemented to cover the requirement of total project. In line with above execution plan should develop around the following parameters.</p>
                     <ul class="icon-list-items">
                        <li class="icon-list-item">
                           <span class="icon-list-icon">
                           <i aria-hidden="true" class="fas fa-check"></i>
                           </span>
                           <span class="icon-list-text">Integrity with client</span>
                        </li>
                        <li class="icon-list-item">
                           <span class="icon-list-icon">
                           <i aria-hidden="true" class="fas fa-check"></i>
                           </span>
                           <span class="icon-list-text">Clients’ objectivities and priorities</span>
                        </li>
                        <li class="icon-list-item">
                           <span class="icon-list-icon">
                           <i aria-hidden="true" class="fas fa-check"></i>
                           </span>
                           <span class="icon-list-text">Network schedule for control of project</span>
                        </li>
                        <li class="icon-list-item">
                           <span class="icon-list-icon">
                           <i aria-hidden="true" class="fas fa-check"></i>
                           </span>
                           <span class="icon-list-text">Total quality management</span>
                        </li>
                        <li class="icon-list-item">
                           <span class="icon-list-icon">
                           <i aria-hidden="true" class="fas fa-check"></i>
                           </span>
                           <span class="icon-list-text">Safety and Health</span>
                        </li>
                     </ul>
                     <blockquote class="wp-block-quote">
                        <p class="mb-0">
                        <p class="mb-0"><strong>Operation Program</strong></p>
                        <p class="mb-0"><strong>Arvind Kumar Construction</strong> Is specialized in planning and execution of projects ON TIME AND WITHIN BUDGET PLANNED through methods engineering, value engineering, contract management and TQM.</p>
                        </p>
                     </blockquote>
                     <div class="row">
                        <div class="col-xl-6 col-md-12">
                           <img src="images/about/01.jpg"  alt="demo-img" class="w-100"/>
                        </div>
                        <div class="col-xl-6 mt-xl-0 mt-lg-4 mt-md-4">
                           <img src="images/about/demo-12.jpg" alt="demo-img" class="demo-12-image"/>
                        </div>
                     </div>
                     <h4 class="mb-2 mt-4">Quality Assurance</h4>
                     <p>A qualified team of Quality Assurance Team shall be deployed on the job to ensure adherence to Quality standards and to perform all quality aspects related to the project.<br/>
                        The team prepares the Quality Assurance Plan that clearly and specifically state that the objects, organization and methods for Quality Assurance.
                     </p>
                     <h4 class="mb-2 mt-4">We provide World Class Quality in Work</h4>
                     <p>We have executed every part of our projects with superior quality and we believe in tendering quality services.
                        We always done work as per FQP Rules in Job.
                     </p>
                     <h4 class="mb-2 mt-4">Safety</h4>
                     <p>Arvind Kumar Construction. Has always maintained a strong commitment to health and safety for the protection of our employees and clients. Arvind Kumar Construction. Health and Safety records are a direct result of a commitment to executing projects in a safe and effective manner. Arvind Kumar Construction has also assembled as experienced safety staff and developed and implemented a Health and Safety Program to ensure this commitment is maintained and continuously improved.</p>
                     <h4 class="mb-2 mt-4">Human Resources Development Services</h4>
                     <p>Supply of Skilled manpower for construction projects. Available sufficient skilled manpower.</p>
                  </div>
               </div>
               <div id="comments" class="gt-comment-area">
                  <div id="respond" class="comment-respond">
                  </div>
               </div>
            </div>
            <div class="col-lg-4">
               <div class="sidebar">
                  <div id="recent-posts-2" class="widget widget_recent_entries">
                     <h2 class="widget-title">Our Services</h2>
                     <ul>
                        <li>
                           <a href="">Thermal Power Project</a>
                        </li>
                        <li>
                           <a href="">Captive Power Plant</a>
                        </li>
                        <li>
                           <a href="">Overhauling</a>
                        </li>
                        <li>
                           <a href="">Critical Piping</a>
                        </li>
                        <li>
                           <a href="">Maintenance of Power Projects</a>
                        </li>
                        <li>
                           <a href="">Supply of Material</a>
                        </li>
                        <li>
                           <a href="">Fabrication</a>
                        </li>
                        <li>
                           <a href="">Fabrication</a>
                        </li>
                     </ul>
                  </div>
                  <div id="recent-comments-2" class="widget widget_recent_comments">
                     <h2 class="widget-title">Project Execution Schedule</h2>
                     <ul>
                        <li>
                           <a href="">Integrity with client</a>
                        </li>
                        <li>
                           <a href="">Clients’ objectivities and priorities</a>
                        </li>
                        <li>
                           <a href="">Network schedule for control of project</a>
                        </li>
                        <li>
                           <a href="">Total quality management</a>
                        </li>
                        <li>
                           <a href="">Safety and Health</a>
                        </li>
                     </ul>
                  </div>
                  <div id="categories-2" class="widget widget_categories">
                     <h2 class="widget-title">Businessess</h2>
                     <ul>
                        <li class="cat-item cat-item-2"><a href="">Power</a>
                        </li>
                        <li class="cat-item cat-item-3"><a href="">Oil And Gas</a>
                        </li>
                        <li class="cat-item cat-item-4"><a href="">Water</a>
                        </li>
                        <li class="cat-item cat-item-5"><a href="">Industry Development</a>
                        </li>
                        <li class="cat-item cat-item-6"><a href="">Telecommunication</a>
                        </li>
                     </ul>
                  </div>
                  <div id="categories-2" class="widget widget_categories">
                     <h2 class="widget-title">Career</h2>
                     <ul>
                        <li class="cat-item cat-item-2"><a href="">Current Opening</a>
                        </li>
                        <li class="cat-item cat-item-3"><a href="">Apply To Architeck</a>
                        </li>
                     </ul>
                  </div>
                  <div id="categories-2" class="widget widget_categories">
                     <h2 class="widget-title">Gallery</h2>
                     <ul>
                        <li class="cat-item cat-item-2"><a href="">Media Gallery</a>
                        </li>
                        <li class="cat-item cat-item-3"><a href="">Latest Updates</a>
                        </li>
                     </ul>
                  </div>
                  <div id="categories-2" class="widget widget_categories">
                     <h2 class="widget-title">Contact Us</h2>
                     <ul>
                        <li class="cat-item cat-item-2"><a href="">Get In Touch</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</section>
<WorkingProgress/>
</>
);
}
import React from "react";

export default function BestServices(){
      return(
            <>
            <section class="procces py-0">
         <div class="container-fluid px-0">
            <div class="row no-gutters">
            <div class="col-lg-3"></div>
               <div class="col-xl-6 text-center">
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title">What We Delivered</span>
                     <h2 class="pt-section-title">Prayagraj Infra Best Services</h2>
                  </div>
               </div>
               <div class="col-lg-3"></div>
               <div class="col-xl-4 col-md-12">
                  <div class="pt-process-step pt-process-1 pt-bg-dark">
                     <div class="pt-process-info">
                        <h3 class="pt-process-title text-white">Thermal Power Project</h3>
                        <p class="pt-process-description text-white">It is a long established fact that a reader will be
                           distracted by the readable content of a page when looking at its layout. 
                        </p>
                     </div>
                     <div class="pt-btn-container">
                        <a class="pt-button pt-btn-link" href="about-us.html">
                           <div class="pt-button-block">
                              <span class="pt-button-line-left pt-btn-line-left"></span>
                              <span class="pt-button-text text-white">Read more</span>
                              <span class="pt-button-line-right pt-btn-line-right"></span>
                              <i class="ion ion-android-arrow-dropright pt-btn-icon  text-white"></i>
                           </div>
                        </a>
                     </div>
                     <span class="pt-process-number">01</span>
                  </div>
               </div>
               <div class="col-xl-4">
                  <div class="pt-bg-overley1 pt-opacity4" style={{backgroundImage: "url('images/process/abhi_4.jpg')"}}></div>
               </div>
               <div class="col-xl-4 col-md-12">
                  <div class="pt-process-step pt-process-1 pt-bg-dark">
                     <div class="pt-process-info">
                        <h3 class="pt-process-title text-white">Captive Power Plant</h3>
                        <p class="pt-process-description text-white">It is a long established fact that a reader will be
                           distracted by the readable content of a page when looking at its layout. 
                        </p>
                     </div>
                     <div class="pt-btn-container">
                        <a class="pt-button pt-btn-link" href="about-us.html">
                           <div class="pt-button-block">
                              <span class="pt-button-line-left pt-btn-line-left"></span>
                              <span class="pt-button-text text-white">Read more</span>
                              <span class="pt-button-line-right pt-btn-line-right"></span>
                              <i class="ion ion-android-arrow-dropright pt-btn-icon  text-white"></i>
                           </div>
                        </a>
                     </div>
                     <span class="pt-process-number">02</span>
                  </div>
               </div>
            </div>
            <div class="row no-gutters">
               <div class="col-xl-4">
                  <div class="pt-bg-overley1 pt-opacity4" style={{backgroundImage: "url('images/process/abhi_5.jpg')"}}></div>
               </div>
               <div class="col-xl-4 col-md-12">
                  <div class="pt-process-step pt-process-1 pt-bg-dark">
                     <div class="pt-process-info">
                        <h3 class="pt-process-title text-white">Overhauling</h3>
                        <p class="pt-process-description text-white">It is a long established fact that a reader will be
                           distracted by the readable content of a page when looking at its layout. 
                        </p>
                     </div>
                     <div class="pt-btn-container">
                        <a class="pt-button pt-btn-link" href="about-us.html">
                           <div class="pt-button-block">
                              <span class="pt-button-line-left pt-btn-line-left"></span>
                              <span class="pt-button-text text-white">Read more</span>
                              <span class="pt-button-line-right pt-btn-line-right"></span>
                              <i class="ion ion-android-arrow-dropright pt-btn-icon  text-white"></i>
                           </div>
                        </a>
                     </div>
                     <span class="pt-process-number">03</span>
                  </div>
               </div>
               <div class="col-xl-4">
                  <div class="pt-bg-overley1 pt-opacity4" style={{backgroundImage: "url('images/process/abhi_6.jpg')"}}></div>
               </div>
            </div>
         </div>
      </section>
            </>
      );
}
import React from "react";

export default function Footer(){
      return(
            <>
            <footer id="pt-footer">
         <div class="pt-footer-style-1">
            <div class="pt-footer-top">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-3 col-md-6">
                        <div class="footer-logo">
                           <div class="row">
                              <div class="col-sm-12">
                                 <img class="img-fluid " src="images/PryjInfraVission.jpg" alt="logo-white"/><br/><br/>
                                 <p class="text-white">M/S Arvind Kumar Construction is headed by Mr Arvind Kumar, an experienced person, who has been in the fabrication and Erection industry for past 7 years and is the Proprietor of Arvind Kumar Construction.
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6">
                        <div class="pt-footer1">
                           <h4 class="footer-title text-white">Useful links</h4>
                           <div class="menu-footer-menu-container">
                              <ul id="menu-footer-menu" class="menu">
                                 <li>
                                    <a href="/about-us">About </a>
                                 </li>
                                 <li>
                                    <a href="/why-choose-us">Why Choose Us</a>
                                 </li>
                                 <li>
                                    <a href="/">Projects</a>
                                 </li>
                                 <li>
                                    <a href="/our-team">Our Team</a>
                                 </li>
                                 <li>
                                    <a href="/contact-us">Contact Us</a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-3  col-md-6 ">
                        <div class="pt-footer1-menu pt-footer1">
                           <h4 class="footer-title">Our Services</h4>
                           <div class="list-inline pt-footer1-menu">
                              <ul class="pt-service">
                                 <li>
                                    <a href="/thermal-power-project">
                                    Thermal Power Project </a>
                                 </li>
                                 <li>
                                    <a href="/captive-plant">
                                    Captive Power Plant </a>
                                 </li>
                                 <li>
                                    <a href="/overhauling">
                                    Overhauling </a>
                                 </li>
                                 <li>
                                    <a href="/critical-piping">
                                    Critical Piping </a>
                                 </li>
                                 <li>
                                    <a href="/maintenance-power-projects">
                                    Maintenance of Power Projects </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div class="col-lg-3  col-md-6">
                        <div class="pt-footer2">
                           <h4 class="footer-title text-white">Our Contact</h4>
                           <div class="row">
                              <div class="col-sm-12">
                                 <ul class="pt-contact pt-2">
                                    <li>
                                       <i class="fas fa-map-marker-alt"></i>
                                       <span class="text-white">
                                       C/o Arvind Kumar, Head Office, MIG II 255 ADA Colony Naini, Dist - Prayagraj, Uttar Pradesh - 211008 </span>
                                     
                                    </li>
                                    <li class="pt-3">
                                       <a href="tel:+1800001658"><i class="fa fa-phone"></i>
                                       <span class="text-white">+91 5323586609</span>
                                       </a>
                                    </li>
                                    <li class="pt-3">
                                       <a href="mailto:info@prayagrajinfraengg.vapsoft.org"><i class="fa fa-envelope"></i><span
                                          class="text-white">info@prayagrajinfraengg.vapsoft.org</span></a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="pt-copyright-footer">
               <div class="container">
                  <div class="row flex-row-reverse">
                     <div class="col-md-6 text-right align-self-center">
                        <span class="pt-copyright"><a target="_blank" href="our-services.html"> Copyright 2023 Prayagraj Infra Engineering All Rights
                        Reserved.</a></span>
                     </div>
                     {/* <div class="col-md-6">
                        <div class="pt-footer-social">
                           <ul>
                              <li><a href=""><i class="fab fa-facebook-f"></i></a></li>
                              <li><a href=""><i class="fab fa-google-plus-g"></i></a></li>
                              <li><a href=""><i class="fab fa-instagram"></i></a></li>
                           </ul>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      </footer>
            </>
      );
}
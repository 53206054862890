import React from "react";
import WorkingProgress from "./WorkingProgress";
export default function OurMission(){
return (
<>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Our Mission & Vission </h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Our Mission & Vission</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<div class="col-xl-12 text-center p-4">
   <div class="pt-section-title-box">
      <h2 class="pt-section-title">Mission of Prayagraj Infra Engineering Pvt Ltd</h2>
   </div>
</div>
<section class="explore-feature pt-bg-dark wow animate__fadeIn" data-wow-delay="0.2s">
   <div class="pt-bg-overley1" style={{backgroundImage: "url('images/bg/01.png')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-xl-6 mb-4 mb-lg-0">
            <div class="about-img-block">
               <img class="img-fluid about-img-0.5" src="images/about/abhi_20.jpg" alt=""/>
            </div>
         </div>
         <div class="col-xl-6  d-flex align-items-center">
            <div class="pt-section-title-box text-xl-left text-md-center text-center mb-0">
               <span class="pt-section-sub-title">Our Mission</span>
               <h2 class="pt-section-title text-white">Project Mission</h2>
               <p class="pt-section-description text-white">The aim is to develop and deploy skilled and highly motivated human resources for infrastructure projects to serve as the client's one-stop solution for all engineering needs.Arvind Kumar Construction's purpose is to promptly qualify projects. within international standards, on schedule, and to the client's total pleasure.superior goods and servicesProvide our consumer with the most affordable pricing possible.To offer the greatest construction solutions in the world, enabling businesses to flourish.focuses on completing the job as quickly and effectively as feasible while maintaining a high standard of safety.</p>
            </div>
         </div>
      </div>
   </div>
</section>
<WorkingProgress/>
</>
);
}
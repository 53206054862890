import React from "react";

export default function WorkingProgress(){
      return(
            <>
            <section class=" 1 py-0">
         <div class="container pt-counter1 pt-bg-primary">
            <div class="row">
               <div class="col-sm-12">
                  <div class="pt-counter-top1 pt-counter">
                     <div class="pt-bg-overley1 pt-opacity5"
                        style={{backgroundImage: "url('images/bg/02.png')"}}>
                     </div>
                     <div class="row">
                        <div class="col-lg-3 col-sm-6 mb-4 mb-lg-0 text-center">
                           <div class="pt-counter-contain mb-md-4 mb-0">
                              <div class="pt-counter-img">
                                 <div class="pt-counter-media">
                                    <i class=" flaticon-008-factory"></i>
                                 </div>
                              </div>
                              <div class="pt-counter-info">
                                 <div class="pt-counter-num-prefix">
                                    <h5 class="timer" data-to="1000" data-speed="5000">1000</h5>
                                    <span class="pt-counter-prefix"></span>
                                 </div>
                                 <p class="pt-counter-description mb-0 text-white">Project Complated</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-4 mb-lg-0 text-center">
                           <div class="pt-counter-contain mb-md-4 mb-0">
                              <div class="pt-counter-img">
                                 <div class="pt-counter-media">
                                    <i class=" flaticon-009-crane"></i>
                                 </div>
                              </div>
                              <div class="pt-counter-info">
                                 <div class="pt-counter-num-prefix">
                                    <h5 class="timer" data-to="270" data-speed="5000">270</h5>
                                    <span class="pt-counter-prefix"></span>
                                 </div>
                                 <p class="pt-counter-description mb-0 text-white">Work Employed</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 mb-4 mb-md-0 text-center">
                           <div class="pt-counter-contain">
                              <div class="pt-counter-img">
                                 <div class="pt-counter-media">
                                    <i class=" flaticon-024-graphic-tool"></i>
                                 </div>
                              </div>
                              <div class="pt-counter-info">
                                 <div class="pt-counter-num-prefix">
                                    <h5 class="timer" data-to="100" data-speed="5000">100</h5>
                                    <span class="pt-counter-prefix"></span>
                                 </div>
                                 <p class="pt-counter-description mb-0 text-white">Projects Under Development</p>
                              </div>
                           </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 text-center">
                           <div class="pt-counter-contain">
                              <div class="pt-counter-img">
                                 <div class="pt-counter-media">
                                    <i class=" flaticon-026-settings-2"></i>
                                 </div>
                              </div>
                              <div class="pt-counter-info">
                                 <div class="pt-counter-num-prefix">
                                    <h5 class="timer" data-to="20" data-speed="5000">20</h5>
                                    <span class="pt-counter-prefix"></span>
                                 </div>
                                 <p class="pt-counter-description mb-0 text-white">Year's of Services</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
            </>
      );
}
import {createRoot} from 'react-dom/client';
import App from './App';

import {BrowserRouter as Router} from 'react-router-dom';
import Header from './Components/Include/Header';
import Footer from './Components/Include/Footer';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// 👇️ wrap App in Router

root.render(
  <Router>
    <Header/>
    <App />
    <Footer/>
  </Router>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


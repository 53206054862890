import React from "react";
import WorkingProgress from "./WorkingProgress";
import BestServices from "./BestServices";
import OurClients from "./OurClients";

export default function Home(){
      return(
            <>
      <p class="rs-p-wp-fix"></p>
      <rs-module-wrap id="rev_slider_1_1_wrapper" data-alias="slider-1-1" data-source="gallery"
         style={{background:'transparent',padding:'0',margin:'0px auto',marginTop:'0',marginBottom:'0'}}>
         <rs-module id="rev_slider_1_1" style={{display:'none'}} data-version="6.2.2">
            <rs-slides>
               <rs-slide data-key="rs-1" data-title="Slide" data-thumb="rev-slider/assets/bg-21-50x100.jpg"
                  data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
                  <img src="rev-slider/assets/abhi_1.jpg"alt="" title="bg-21.jpg" width="1920" height="1080" class="rev-slidebg"
                     data-no-retina/>
                  <rs-layer id="slider-1-slide-1-layer-2" data-type="text" data-color="#002155" data-rsp_ch="on"
                     data-xy="xo:45px,40px,30px,15px;y:m;yo:-17px,-13px,0,-22px;"
                     data-text="w:normal;s:92,72,72,50;l:92,72,72,50;fw:600;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:900;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'10',fontFamily:'Jost',sansSerif:''}}>Build A Better<br />
                     World
                  </rs-layer>
                  <rs-layer id="slider-1-slide-1-layer-3" data-type="text" data-color="#565969" data-rsp_ch="on"
                     data-xy="xo:45px,40px,30px,15px;y:m;yo:108px,95px,76px,45px;"
                     data-text="w:normal;s:16,16,16,11;l:32,32,32,24;" data-dim="minh:0px,none,none,none;"
                     data-vbility="t,t,f,f" data-frame_0="x:50,39,29,17;" data-frame_1="st:1200;sp:1000;"
                     data-frame_999="o:0;st:w;" style={{zIndex:'10',fontFamily:'Jost', sansSerif:''}}>It is a long
                     established
                     fact that a reader will be distracted by the readable content of a<br />page when looking at its
                     layout.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-1-layer-4" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="xo:45px,40px,30px,15px;y:m;yo:204px,180px,125px,63px;"
                     data-text="w:normal;s:16,16,16,18;l:32,30,32,30;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1500;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#002155;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'9',backgroundColor:'#ff631b',fontFamily:'Jost', sansSerif:''}}>Explore More
                  </rs-layer>
                  <rs-layer id="slider-1-slide-1-layer-5" data-type="text" data-color="#ff631b" data-rsp_ch="on"
                     data-xy="xo:50px,40px,30px,15px;y:m;yo:-137px,-107px,-97px,-100px;"
                     data-text="w:normal;s:16,16,16,18;l:30,32,32,30;fw:600;" data-padding="l:10,8,6,4;"
                     data-border="bos:solid;boc:#ff5e14;bow:0,0,0,2px;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:600;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'12',fontFamily:'Jost', sansSerif:''}}>Great Experience In Building. </rs-layer>
                  <rs-layer id="slider-1-slide-1-layer-6" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="xo:220px,199px,170px,154px;y:m;yo:204px,180px,125px,63px;"
                     data-text="w:normal;s:16,16,16,18;l:32,30,32,30;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1800;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#ff631b;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'8',backgroundColor:'#002155',fontFamily:'Jost', sansSerif:''}}>Get In Touch<br />
                  </rs-layer>
               </rs-slide>
               <rs-slide data-key="rs-2" data-title="Slide" data-thumb="rev-slider/assets/bg-3343-50x100.jpg"
                  data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
                  <img src="rev-slider/assets/abhi_2.jpg"alt="" title="bg-3343" width="1920" height="1080" class="rev-slidebg"
                     data-no-retina/>
                  <rs-layer id="slider-1-slide-2-layer-2" data-type="text" data-color="#002155" data-rsp_ch="on"
                     data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:-17px,-13px,0,-3px;"
                     data-text="w:normal;s:92,72,62,42;l:92,72,62,42;fw:600;a:right;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:900;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'10',fontFamily:'Jost', sansSerif:'', textTransform:'capitalize'}}>We Are Best
                     Builders<br />
                     In The world
                  </rs-layer>
                  <rs-layer id="slider-1-slide-2-layer-3" data-type="text" data-color="#565969" data-rsp_ch="on"
                     data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:108px,85px,48px,39px;"
                     data-text="w:normal;s:16,12,16,11;l:32,25,32,22;a:right;" data-dim="minh:0px,none,none,none;"
                     data-vbility="t,t,f,f" data-frame_0="x:50,39,29,17;" data-frame_1="st:1200;sp:1000;"
                     data-frame_999="o:0;st:w;" style={{zIndex:'11',fontFamily:'Jost', sansSerif:''}}>It is a long
                     established
                     fact that a reader will be distracted by the readable content of a<br />page when looking at its
                     layout.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-2-layer-4" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:204px,160px,125px,83px;"
                     data-text="w:normal;s:16,12,16,18;l:32,25,32,30;a:center;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1500;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#002155;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'9', backgroundColor:'#ff631b', fontFamily:'Jost', sansSerif:''}}>Explore More<br />
                  </rs-layer>
                  <rs-layer id="slider-1-slide-2-layer-5" data-type="text" data-color="#ff631b" data-rsp_ch="on"
                     data-xy="x:r;xo:50px,40px,30px,15px;y:m;yo:-137px,-107px,-87px,-66px;"
                     data-text="w:normal;s:16,12,16,18;l:30,23,32,22;fw:600;" data-padding="l:10,8,6,4;"
                     data-border="bos:solid;boc:#ff631b;bow:0,0,0,2px;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:600;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'12',fontFamily:'Jost', sansSerif:'',textTransform:'capitalize'}}>Great Experience in
                     Bulding.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-2-layer-6" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="x:r;xo:246px,190px,187px,163px;y:m;yo:204px,160px,125px,83px;"
                     data-text="w:normal;s:16,12,16,18;l:32,25,32,30;a:center;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1800;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#ff631b;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'8', backgroundColor:'#002155',fontFamily:'Jost', sansSerif:''}}>Get In Touch<br />
                  </rs-layer>
               </rs-slide>
               <rs-slide data-key="rs-3" data-title="Slide" data-thumb="rev-slider/assets/bg-333-50x100.jpg"
                  data-anim="ei:d;eo:d;s:600;r:0;t:fade;sl:0;">
                  <img src="rev-slider/assets/abhi_3.jpg" alt="" title="bg-333" width="1920" height="1080" class="rev-slidebg"
                     data-no-retina/>
                  <rs-layer id="slider-1-slide-3-layer-2" data-type="text" data-rsp_ch="on"
                     data-xy="xo:50px,40px,30px,15px;y:m;yo:-17px,-13px,0,-2px;"
                     data-text="w:normal;s:92,72,56,42;l:92,72,60,48;fw:600;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:900;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'10',fontFamily:'Jost', sansSerif:'', textTransform:'capitalize'}}>Provide
                     Effective<br />
                     Solutions.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-3-layer-3" data-type="text" data-rsp_ch="on"
                     data-xy="xo:50px,40px,30px,15px;y:m;yo:118px,92px,28px,37px;"
                     data-text="w:normal;s:16,12,16,11;l:32,25,32,22;" data-dim="minh:0px,none,none,none;"
                     data-vbility="t,t,f,f" data-frame_0="x:50,39,29,17;" data-frame_1="st:1200;sp:1000;"
                     data-frame_999="o:0;st:w;" style={{zIndex:'11',fontFamily:'Jost', sansSerif:''}}>It is a long
                     established
                     fact that a reader will be distracted by the readable content of a<br />page when looking at its
                     layout.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-3-layer-4" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="xo:50px,40px,30px,15px;y:m;yo:214px,168px,115px,84px;"
                     data-text="w:normal;s:16,12,16,18;l:32,25,32,30;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1500;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#002155;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'9',backgroundColor:'#ff5e14',fontFamily:'Jost', sansSerif:''}}>Explore More
                  </rs-layer>
                  <rs-layer id="slider-1-slide-3-layer-5" data-type="text" data-rsp_ch="on"
                     data-xy="xo:50px,40px,30px,15px;y:m;yo:-127px,-100px,-87px,-74px;"
                     data-text="w:normal;s:16,12,16,18;l:30,23,26,24;fw:600;" data-padding="l:10,8,6,4;"
                     data-border="bos:solid;boc:#ff631b;bow:0,0,0,2px;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:600;sp:1000;" data-frame_999="o:0;st:w;"
                     style={{zIndex:'12', fontFamily:'Jost', sansSerif:'', textTransform:'capitalize'}}>Great Experience in
                     Bulding.
                  </rs-layer>
                  <rs-layer id="slider-1-slide-3-layer-6" class="rev-btn" data-type="button" data-rsp_ch="on"
                     data-xy="xo:245px,189px,193px,164px;y:m;yo:214px,168px,115px,84px;"
                     data-text="w:normal;s:16,12,16,18;l:32,25,32,30;" data-dim="minh:0px,none,none,none;"
                     data-padding="t:12,9,7,4;r:32,25,19,12;b:12,9,7,4;l:32,25,19,12;" data-frame_0="x:50,39,29,17;"
                     data-frame_1="st:1800;sp:1000;" data-frame_999="o:0;st:w;"
                     data-frame_hover="bgc:#ff5e14;bor:0px,0px,0px,0px;sp:200;e:power1.inOut;"
                     style={{zIndex:'8',backgroundColor:'#002155', fontFamily:'Jost', sansSerif:''}}>Get In Touch<br />
                  </rs-layer>
               </rs-slide>
            </rs-slides>
            <rs-progress class="rs-bottom" style={{visibility: 'hidden !important'}}></rs-progress>
         </rs-module>
      </rs-module-wrap>
      <section class="about-us">
         <div class="container">
            <div class="row d-flex align-items-center">
               <div class="col-xl-6  col-lg-12 text-center">
                  <div id="44c58" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                     data-direction="lr">
                     <img class="img-fluid" src="images/about/abhi_9.jpg" alt="demo-img"/>
                  </div>
               </div>
               <div class="col-xl-6 pt-lg-4 col-12 pt-md-4 mt-4">
                  <div class="pt-section-title-box text-left">
                     <span class="pt-section-sub-title">Construction Experience</span>
                     <h2 class="pt-section-title">We Building Everything Best That You Needed</h2>
                     <p class="pt-section-description">M/S Arvind Kumar Construction is headed by Mr Arvind Kumar, an experienced person, who has been in the fabrication and Erection industry for past 10 years and is the Proprietor of Arvind Kumar Construction.
                     </p>
                     <p class="mt-5">Under his excellent and innovative leadership along with his perseverance, Arvind Kumar Construction is increasing its reach in the steel fabrication and erection industry exponentially. Though the roots of the company can be found to lead us to Arvind Kumar Construction deals in technically specialised and diversified structural area.
                     </p>
                  </div>
                  <div class="row">
                     <div class="col-4 col-md-3">
                        <img class="img-fluid sign-img" src="images/sign.png" alt="demo-img"/>
                     </div>
                     <div class="col-8 col-md-9 mt-4 d-flex align-items-center">
                        <div class="image-box-content">
                           <h4 class="elementor-image-box-title">Mr. Arvind Kumar</h4>
                           <p class="elementor-image-box-description">Chairman & Managing Director</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="backgrount-image-right d-md-inline-block wow animate__fadeInRight">
            <img class="img-fluid" src="images/bg/bg-01.png" alt="bg-img"/>
         </div>
      </section>
      <BestServices/>
      <section class="process-step wow animate__fadeIn" data-wow-delay="0.2s">
         <div class="container">
            <div class="row">
               <div class="col-xl-3">
               </div>
               <div class="col-xl-6 text-center">
                  <div class="pt-big-title mt-4">
                     <h2 class="pt-big-title1  wow animate__fadeInUp" data-wow-duration="1s"
                        data-wow-delay="1s">Process</h2>
                  </div>
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title">Our Work Process</span>
                     <h2 class="pt-section-title">How Prayagraj Infra Works</h2>
                    
                  </div>
               </div>
               <div class="col-xl-3">
               </div>
            </div>
            <div class="row  mt-4 mt-lg-5">
               <div class="col-xl-4 col-lg-6 col-md-6 mb-5 mb-lg-0 text-center">
                  <div class="pt-process-step pt-process-2">
                     <img class="pt-before-img d-xl-inline-block d-none " src="images/arrow-2.png" alt="arrow-img"/>
                     <div class="pt-process-media">
                        <i class=" flaticon-015-calculator-1"></i>
                        <span class="pt-process-number">1</span>
                     </div>
                     <div class="pt-process-info">
                        <h3 class="pt-process-title">Project Planing</h3>
                        <p class="pt-process-description">The discipline of project planning deals with how to finish a project within a specific amount of time, typically with specified phases and resources.
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-6 col-md-6 mb-5 mb-lg-0 text-center">
                  <div class="pt-process-step pt-process-2">
                     <img class="pt-before-img  d-xl-inline-block d-none" src="images/arrow-1.png" alt="arrow-img"/>
                     <div class="pt-process-media">
                        <i class=" flaticon-030-presentation"></i>
                        <span class="pt-process-number">2</span>
                     </div>
                     <div class="pt-process-info">
                        <h3 class="pt-process-title">General Contract</h3>
                        <p class="pt-process-description">Legal papers known as general contracts are signed by both parties and include the terms and circumstances of the agreement.

                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 col-md-12 text-center pt-xl-0 pt-md-4">
                  <div class="pt-process-step pt-process-2">
                     <div class="pt-process-media">
                        <i class=" flaticon-044-design-tools"></i>
                        <span class="pt-process-number">3</span>
                     </div>
                     <div class="pt-process-info">
                        <h3 class="pt-process-title">Interior Design</h3>
                        <p class="pt-process-description">The professional and all-encompassing process of designing an interior space that attends to, and fulfills human need is known as interior design.

                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class=" working-with-excellent pt-q pt-bg-dark  wow animate__fadeIn" data-wow-delay="0.2s">
         <div class="pt-bg-overley pt-opacity1" style={{backgroundImage: "url('images/bg/01.png')"}}></div>
         <div class="container">
            <div class="row ">
               <div class="col-xl-6">
                  <div class="pt-section-title-box text-left">
                     <span class="pt-section-sub-title text-white border-white">Working With Excellent</span>
                     <h2 class="pt-section-title text-white">Construction & Engineering Real Solutions.</h2>
                     <p class="pt-section-description text-white">The process of supervising and controlling a large-scale, intricate building project is known as construction engineering. Large buildings and the infrastructure needed to support them are typically the focus of these kinds of construction projects.

                     </p>
                  </div>
                  <ul class="icon-list-items text-white">
                     <li class="icon-list-item">
                        <span class="icon-list-icon">
                        <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="icon-list-text">Organising and creating construction plans and technical drawings</span>
                     </li>
                     <li class="icon-list-item">
                        <span class="icon-list-icon">
                        <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="icon-list-text">Examining work schedules and assigning assignments to employees</span>
                     </li>
                     <li class="icon-list-item">
                        <span class="icon-list-icon">
                        <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="icon-list-text">Arranging building supplies and settling vendor agreements</span>
                     </li>
                     <li class="icon-list-item">
                        <span class="icon-list-icon">
                        <i aria-hidden="true" class="fas fa-check"></i>
                        </span>
                        <span class="icon-list-text">Making initial site surveys at possible building locations</span>
                     </li>
                  </ul>
                  <div class="mt-5">
                     <a class="pt-button pt-bg-primary" href="/about-us">
                        <div class="pt-button-block">
                           <span class="pt-button-line-left"></span>
                           <span class="pt-button-text">View More</span>
                           <span class="pt-button-line-right"></span>
                           <i class="ion ion-android-arrow-dropright pt-btn-icon"></i>
                        </div>
                     </a>
                  </div>
               </div>
                
               <div class="col-xl-6 col-12 mt-md-4 mt-lg-4 mt-4 d-flex align-items-center">
                  <div id="44c57" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
                           data-direction="rl">
                  <img class="img-fluid" src="images/about/abhi_7.jpg" alt="demo-img "/>
               </div>
               </div>
            </div>
         </div>
      </section>
      <WorkingProgress/>
      <section class="team md-pt-0">
         <div class="container">
            <div class="row">
               <div class="col-lg-2"></div>
               <div class="col-lg-8 text-center">
                  <div class="pt-big-title mt-4">
                     <h2 class="pt-big-title1  wow animate__fadeInUp" data-wow-duration="1s"
                        data-wow-delay="1s">challenger</h2>
                  </div>
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title  border-left ">Would Like To Hear From You!</span>
                     <h2 class="pt-section-title">Our Expert Team</h2>
                  </div>
               </div>
               <div class="col-lg-2"></div>
            </div>
         </div>
         <div class="container-pt">
            <div class="row mt-4 mt-lg-5">
               <div class="col-sm-12">
                  <div class="owl-carousel" data-dots="false" data-nav="false" data-desk_num="4" data-lap_num="2"
                     data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="true" data-loop="true"
                     data-margin="30">
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/01.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Chairman & Managing Director</span>
                              <h5><a class="pt-member-name">Mr. Arvind Kumar</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/02.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Dy. General Manager - Projects</span>
                              <h5><a class="pt-member-name">Mr. Suraj M. Ingle</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/03.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">HOD HR/Admin</span>
                              <h5><a class="pt-member-name">Mr. Dinesh Kumar</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/04.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Officer, HR-Admin</span>
                              <h5><a class="pt-member-name">Mr. D.P. Nishad</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/05.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Stock Market</span>
                              <h5><a class="pt-member-name">Danial Frankie</a></h5>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
          <OurClients/>
          <section class="conatct-us pt-bg-dark section-style">
         <div class="pt-bg-overley1" style={{backgroundImage: "url('images/bg/01.png')"}}></div>
         <div class="container ">
            <div class="row pb-5">
               <div class="col-xl-6 z-index-1">
                  <div class="pt-section-title-box text-xl-left text-center  text-xl-white mb-md-0">
                     <span class="pt-section-sub-title text-white border-white">Contact Us Today</span>
                     <h2 class="pt-section-title text-white">For All Your Construction Needs!</h2>
                     <p class="pt-section-description text-white">It is a long established fact that a reader will be
                        distracted by the readable content sof a page when looking at its layout.
                     </p>
                  </div>
               </div>
               <div class="col-xl-6 d-xl-flex justify-content-xl-end align-items-center ">
                  <div class="pt-btn-container align-items-center text-center">
                     <a class="pt-button pt-btn-flat pt-bg-primary " href="our-services.html">
                        <div class="pt-button-block">
                           <span class="pt-button-line-left"></span>
                           <span class="pt-button-text">contect us</span>
                           <span class="pt-button-line-right"></span>
                           <i class="ion ion-android-arrow-dropright pt-btn-icon"></i>
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class=" conatct pt-contact-form pt-section-overlap pt-5">
         <div class="container">
            <div class="row no-gutters">
               <div class="col-xl-6 z-index-1">
                  <div class="form p-4 p-md-5 pt-bg-light">
                     <div class="pt-bg-overley pt-opacity1 "
                        style={{backgroundImage: "url('images/bg/03.png')"}}>
                     </div>
                     <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">Get Quote</span>
                        <h2 class="pt-section-title">Request A Quote</h2>
                     </div>
                     <form class="quote-from">
                        <div class="row">
                           <div class="col-md-6">
                              <div class="form-group">
                                 <input type="text" class="form-control" id="exampleInputName" placeholder="Enter name"/>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="form-group">
                                 <input type="email" class="form-control" id="Email" placeholder="Enter email"/>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="form-group">
                                 <input type="text" class="form-control" id="Subject" placeholder="Enter subject"/>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="form-group">
                                 <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"
                                    placeholder="Enter your message"></textarea>
                              </div>
                           </div>
                           <div class="col-md-12">
                              <div class="form-group">
                                 <input type="submit" value="Send Message" class="wpcf7-form-control wpcf7-submit"/>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div class="col-xl-6 d-lg-inline-block"
                  style={{backgroundImage: "url('images/about/abhi_8.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', center:''}}>
               </div>
            </div>
         </div>
      </section>
      </section>
      <div id="back-to-top">
         <a class="topbtn" id="top" href="#top"> <i class="ion-ios-arrow-up"></i> </a>
      </div>
            </>
           
      );
}
import React from "react";
import WorkingProgress from "./WorkingProgress";

export default function Projects(){
return (
<>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Our Projects</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Our Projects</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="services wow animate__fadeIn" data-wow-delay="0.2s">
         <div class="container">
            <div class="row">
               <div class="col-lg-3"></div>
               <div class="col-lg-6  text-center">
                  <div class="pt-big-title mt-4">
                     <h2 class="pt-big-title1">SERVICES</h2>
                  </div>
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title">What We Offe</span>
                     <h2 class="pt-section-title">Prayagraj Infra Delivered Projects</h2>
                     <p class="pt-section-description">Deliverables are essentially the outcomes of project activities that are produced by projects. The size and number of project deliverables vary depending on the project. During the project planning phase, the project management team and stakeholders agree on them.

                     </p>
                  </div>
               </div>
               <div class="col-lg-3"></div>
            </div>
            <div class="row">
               <div class="col-xl-4 col-lg-6 col-md-6  mb-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-6.jpg" alt=""/>
                        <div class="pt-service-media">
                        <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">RH-01 Modification Work</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-6 col-md-6 mb-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-3.jpg" alt=""/>
                        <div class="pt-service-media">
                        <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Flue Gas Duct & Structure Fabrication & Erection Work</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">L&T MHI Power Boiler Pvt.Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mb-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-2.jpg" alt=""/>
                        <div class="pt-service-media">
                        <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">INSTALLATION OF SOFT SEALS IN PAPH A & B</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-6 col-md-6 mb-4 mb-lg-0">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-7.jpg" alt=""/>
                        <div class="pt-service-media">
                        <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">SG Package of Meja 2x660 MW TPP – Restoration of unit-2</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-6 col-md-6 mb-4 mb-md-0">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-1.jpg" alt=""/>
                        <div class="pt-service-media">
                        <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">ESP Unit #02</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">GE Power India Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Replacement of Expansion Bello</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Balance Work of Boiler Unit#02 MUNPL Meja</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Piping, Equipment & Structure at HURL Gorakhpur</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Fly Ash Silo & Utlity Building Piping & Structure</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">MEJA URJA NIGAM PVT.LTD</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Erection of ACW Pumps, CW Pumps, Hoist & DSL with Accessories</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Erection os Sec Insert Gate, Filter & Screens</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">LP Piping at NUPPL Ghatampur</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Fabrication and Erection at NUPPL Ghatampur</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">CWPH at NUPPL Ghatampur</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Structural Fabrication And Erection</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">Simplex Infrastrutures Limited</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Structural Fabrication And Erection</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">Simplex Infrastrutures Limited</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Fabrication of Secondary Steel Structure & ID Duct Structure U#2</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Supply of Manpower along with T&P and consumables for ASH Handling System</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">MEJA URJA NIGAM PVT.LTD</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Removal of Insulation Scrape and Dumping at located area</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">MEJA URJA NIGAM PVT.LTD</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Balance C&I erection Work of ash handling system</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">MEJA URJA NIGAM PVT.LTD</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Manpower Supply for RH-2 & Commissioning</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Fabrication and Erection Platform Structure for Actuators</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">MEJA URJA NIGAM PVT.LTD</span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4 col-lg-12 col-md-6 mt-xl-0 mt-lg-5">
                  <div class="pt-service-box pt-service-box-1">
                     <div class="pt-service-banner">
                        <img class="img-fluid" src="images/blog/blog-4.jpg" alt=""/>
                        <div class="pt-service-media">
                           <i class=" flaticon-022-repair-tools"></i>
                        </div>
                     </div>
                     <div class="pt-service-box-info">
                        <h5 class="pt-service-title">Erection of Steam Blowing (temp) piping</h5>
                        <div class="pt-section-title-box">
                        <span class="pt-section-sub-title">BGR Energy Systems Ltd.</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <WorkingProgress/>

</>
);
}
import React from "react";
import WorkingProgress from "./WorkingProgress";
export default function WhyChooseUs(){
return(
<>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Why Choose Us </h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Why Choose Us</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<WorkingProgress/>
<section class="accordian">
   <div class="container">
      <div class="col-xl-12 text-center p-4">
         <div class="pt-section-title-box">
            <h2 class="pt-section-title">Why Choose Prayagraj Infra Engineering Pvt Ltd</h2>
         </div>
      </div>
      <div class="row d-flex align-items-center">
         <div class="about-img-block text-center col-xl-6 mb-5 mb-xl-0">
            <img class="img-fluid about-img" src="images/about/demo-10.png" alt="demo-img"/>
            <img class="img-fluid about-img-5 d-xl-inline-block d-md-inline-block d-none"
               src="images/about/demo-6.png" alt=""/>
         </div>
         <div class="col-xl-6">
            <div class="pt-section-title-box text-left">
               <span class="pt-section-sub-title">Standard In Industrial Contracting.</span>
               <h2 class="pt-section-title">We Building Everything That Needed You</h2>
               <p class="pt-section-description">Because it directly affects organisational performance, cohesiveness, and growth, effective team management is essential. It makes sure that jobs are assigned optimally depending on each person's skills, which raises output and improves work quality.

               </p>
            </div>
            <div class="pt-accordion-block">
               <div class="pt-accordion-box pt-active 1">
                  <div class="pt-ad-title">
                     <h4 class="ad-title-text">
                     Experienced & Expertise Management Team.
                        <i aria-hidden="true" class="ion ion-ios-arrow-down active"></i><i aria-hidden="true"
                           class="ion ion-ios-arrow-up inactive"></i>
                     </h4>
                  </div>
                  <div class="pt-accordion-details">
                     <p class="pt-detail-text"> A good place to start is Warren G. Bennis's comment, "Leaders are people who do the right things; managers are people who do things right." Leadership is crafting a compelling vision of the future, conveying that vision, and assisting people in understanding and committing to it. 
                     </p>
                  </div>
               </div>
               <div class="pt-accordion-box 2">
                  <div class="pt-ad-title">
                     <h4 class="ad-title-text">
                     Equipped With Experienced & Quality Technicians.
                        <i aria-hidden="true" class="ion ion-ios-arrow-down active"></i><i aria-hidden="true"
                           class="ion ion-ios-arrow-up inactive"></i>
                     </h4>
                  </div>
                  <div class="pt-accordion-details">
                     <p class="pt-detail-text"> Quality technicians keep unsatisfactory products off the shelf by inspecting materials for defects and deviations from original product specifications. Quality technicians check, accept, or reject items based on their longevity, function, customer happiness, and overall quality.

                     </p>
                  </div>
               </div>
               <div class="pt-accordion-box 3">
                  <div class="pt-ad-title">
                     <h4 class="ad-title-text">Handling Manpower Capacity of Any Size.
                        <i aria-hidden="true" class="ion ion-ios-arrow-down active"></i><i aria-hidden="true"
                           class="ion ion-ios-arrow-up inactive"></i>
                     </h4>
                  </div>
                  <div class="pt-accordion-details">
                     <p class="pt-detail-text"> Most businesses' primary activity is human resource management. Planning, organising, staffing, training, and evaluation are all part of the process. It also entails developing processes for analysing, monitoring, managing, and rewarding personnel based on their performance.

                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</>
);
}
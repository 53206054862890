import React from "react";

export default function OurClients(){
      return(
            <>
               <section class="client border-bottom">
                  <div class="container">
                  <div class="row">
                     <div class="col-lg-3"></div>
                     <div class="col-xl-6 text-center">
                        <div class="pt-section-title-box">
                           <span class="pt-section-sub-title">Best Service, Right Time, Right People</span>
                           <h2 class="pt-section-title">OUR CLIENTS</h2>
                           <p class="pt-section-description">Our clients share the belief that a genuine collaboration is a two-way street where ideas and information are shared freely and often, built on a foundation of mutual trust and respect for one another's areas of expertise.

                           </p>
                        </div>
                     </div>
                     <div class="col-lg-3"></div>
                  </div>
                  <div class="row">
                     <div class="col-lg-3 col-md-4 col-sm-6 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/01.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/001.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/02.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/002.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/03.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/003.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/04.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/004.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 pt-4  pt-md-0 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/05.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/005.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 pt-4  pt-md-0 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/06.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/006.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 pt-4 pt-md-0 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/07.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/007.png" alt="pt-client-img"/>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-4 col-sm-6 pt-4 pt-md-0 text-center">
                        <div class="pt-client-grid">
                           <img class="img-fluid pt-client-img" src="images/client/08.png" alt="pt-client-img"/>
                           <img class="img-fluid pt-client-hover-img" src="images/client/008.png" alt="pt-client-img"/>
                        </div>
                     </div>
                  </div>
                  </div>
               </section>
            </>
         );
}
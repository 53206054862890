import React from "react";
export default function OurTeam(){
return (
<>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Our Team</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Our Leadership</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="team md-pt-0">
         <div class="container">
            <div class="row">
               <div class="col-lg-2"></div>
               <div class="col-lg-8 text-center">
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title  border-left ">Would Like To Hear From You!</span>
                     <h2 class="pt-section-title">Our Expert Team</h2>
                     <p class="pt-section-description">Teamwork is a crucial component of a company's work culture since it allows employees to exchange ideas, communicate freely, distribute workload, and work collaboratively towards a common objective. Interviewers may ask this question to see if you understand the value of teamwork.

                     </p>
                  </div>
               </div>
               <div class="col-lg-2"></div>
            </div>
         </div>
         <div class="container-pt">
            <div class="row mt-4 mt-lg-5">
               <div class="col-sm-12">
                  <div class="owl-carousel" data-dots="false" data-nav="true" data-desk_num="4" data-lap_num="2"
                     data-tab_num="2" data-mob_num="1" data-mob_sm="1" data-autoplay="true" data-loop="true"
                     data-margin="30">
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/01.jpg" alt=""/>
                              
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Chairman & Managing Director</span>
                              <h5><a class="pt-member-name">Mr. Arvind Kumar</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/02.jpg" alt=""/>
                              
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Dy. General Manager - Projects</span>
                              <h5><a class="pt-member-name">Mr. Suraj M. Ingle</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/03.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">HOD HR/Admin</span>
                              <h5><a class="pt-member-name">Mr. Dinesh Kumar</a></h5>
                           </div>
                        </div>
                     </div>
                     <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/04.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Officer, HR-Admin</span>
                              <h5><a class="pt-member-name">Mr. D.P. Nishad</a></h5>
                           </div>
                        </div>
                     </div>
                     {/* <div class="item">
                        <div class="pt-team-box text-center">
                           <div class="pt-team-img">
                              <img class="img-fluid" src="images/team/05.jpg" alt=""/>
                           </div>
                           <div class="pt-team-info">
                              <span class="pt-team-designation">Stock Market</span>
                              <h5><a class="pt-member-name">Danial Frankie</a></h5>
                           </div>
                        </div>
                     </div> */}
                  </div>
               </div>
            </div>
         </div>
      </section>
</>
);
}
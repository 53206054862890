import React from "react";

export default function MaintenanceofPowerProjects(){
return (
<div>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Maintenance of Power Projects</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Maintenance of Power Projects</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Brief About Maintenance of Power Projects</h2>
               <p class="pt-section-description">Power plant maintenance include examining, maintaining, repairing, and replacing equipment, machinery, and other assets that support power plants' day-to-day operations. Every piece of equipment in a power plant, no matter how large or little, plays an important part in reaching daily targets and supporting the facility's overall operations. As a result, power plant equipment maintenance is a major concern, as failure of equipment can have disastrous implications ranging from decreased plant production to full-fledged outages.<br/><br/>Strategic power plant maintenance necessitates foresight. Power plants that aspire for proactive maintenance should take activities now that will result in improved results and performance in the future. There is a multitude of current power tools available to assist build this proactive mindset.</p>
            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">O&M Training</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Condition Monitoring</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Power Plant Conditioning Monitoring</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Diesel Power Plant Operation</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Distribution System Training </span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Environmental Protection Control</span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/abhi_19.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
);
}
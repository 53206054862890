import React from "react";

export default function Infrastructure(){
return (
<div>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Infrastructure</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Infrastructure</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Brief About Infrastructure</h2>
               <p class="pt-section-description">Infrastructure is described as a company's, region's, or nation's basic physical systems, and it frequently involves the generation of public goods or manufacturing processes. Transportation systems, communication networks, sewage, water, and education systems are all examples of infrastructure.<br/><br/>Infrastructure investments are typically costly and capital-intensive, yet they are critical to a region's economic development and success. Infrastructure improvement projects can be sponsored publicly, privately, or through public-private partnerships.<br/><br/>The term infrastructure, borrowed from French, originally used in the late 1880s, with infra- meaning underneath and structure meaning building. Infrastructure can refer to the basis upon which an economy's structure is built.</p>
            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Hard Infrastructure</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Soft Infrastructure</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Maintaining Infrastructure</span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/abhi_17.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
);
}
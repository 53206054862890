import React from "react";

export default function ContactUs(){
      return(
            <>
              <section class="pt-breadcrumb">
         <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
         <div class="container">
            <div class="row">
               <div class="col-sm-12">
                  <h1 class="pt-breadcrumb-title">Contact Us</h1>
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                     </ol>
                  </nav>
               </div>
            </div>
         </div>
      </section>
      <section class="pt-section">
         <div class="container">
            <div class="row">
               <div class="col-xl-4  col-lg-6 col-12 mb-4">
                  <div class="icon-box1 text-center">
                     <div class="icon-box-icon">
                        <span class="icon animation">
                        <i class="fas fa-map-marker-alt"></i>
                        </span>
                     </div>
                     <div class="icon-box-content">
                        <h3 class="icon-box-title">
                           <span>Our Location</span>
                        </h3>
                        <p class="icon-box-description">C/o Arvind Kumar, Head Office, MIG II 255 ADA Colony Naini, Dist - Prayagraj, Uttar Pradesh - 211008</p>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4  col-lg-6 col-12 mb-4 mb-sm-0 mt-lg-0">
                  <div class="icon-box1 text-center">
                     <div class="icon-box-icon">
                        <span class="icon animation">
                        <i class="fas fa-phone-alt"></i>
                        </span>
                     </div>
                     <div class="icon-box-content">
                        <h3 class="icon-box-title">
                           <span>Our Location</span>
                        </h3>
                        <p class="icon-box-description">
                        +91 5323586609
                        </p>
                     </div>
                  </div>
               </div>
               <div class="col-xl-4  col-lg-12 col-12 mt-md-4 mt-lg-0">
                  <div class="icon-box1 text-center">
                     <div class="icon-box-icon">
                        <span class="icon animation">
                        <i class="fas fa-envelope"></i>
                        </span>
                     </div>
                     <div class="icon-box-content">
                        <h3 class="icon-box-title">
                           <span>Mail us</span>
                        </h3>
                        <a href="mailto:info@prayagrajinfraengg.vapsoft.org"><i class="fas fa-envelope"></i><span>info@prayagrajinfraengg.vapsoft.org</span></a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="backgrount-image-right d-md-inline-block wow animate__fadeInRight" style={{visibility: 'visible', animationName: 'fadeInRight'}}>
            <img class="img-fluid" src="images/bg/bg-01.png" alt="bg-img"/>
         </div>
      </section>
      <div class="position-relative">
      <iframe class="pt-ifrm" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14418.413562432033!2d81.8743296!3d25.3846055!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39854a7b8192d42b%3A0xed4138b19fc4a545!2sArvind%20Kumar!5e0!3m2!1sen!2sin!4v1703849711720!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <section class="conatct pt-contact-form pt-section-overlap ">
         <div class="container">
            <div class="row no-gutters">
               <div class="col-xl-6 p-4 p-md-5 pt-bg-light">
                  <div class="pt-bg-overley1 pt-opacity1"
                     style={{backgroundImage: "url('images/bg/03.png')"}}>
                  </div>
                  <div class="pt-section-title-box">
                     <span class="pt-section-sub-title">Get Quote</span>
                     <h2 class="pt-section-title">Request A Quote</h2>
                  </div>
                  <form class="quote-from">
                     <div class="row">
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="text" class="form-control" id="exampleInputName" placeholder="Enter name"/>
                           </div>
                        </div>
                        <div class="col-md-6">
                           <div class="form-group">
                              <input type="email" class="form-control" id="Email" placeholder="Enter email"/>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <input type="text" class="form-control" id="Subject" placeholder="Enter subject"/>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <textarea class="form-control" id="exampleFormControlTextarea1" rows="6"
                                 placeholder="Enter your message"></textarea>
                           </div>
                        </div>
                        <div class="col-md-12">
                           <div class="form-group">
                              <button class=" btn btn-primary pt-bg-primary text-white border-0" type="submit">Send Message</button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
               <div class="col-xl-6 d-lg-inline-block"
                  style={{backgroundImage: "url('images/about/abhi_8.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', center:''}}>
               </div>
            </div>
         </div>
      </section>
      {/* <section class="action-box py-4 pt-bg-primary">
         <div class="container">
            <div class="row">
               <div class="col-xl-6 col-12 mb-xl-0">
                  <div class="pt-subscribe-block">
                     <img src="images/mail.png" class="img-fluid pt-subscribe-img" alt=""/>
                     <div class="pt-subscribe-details">
                        <h6>
                           Sign Up to Get Latest Updates
                        </h6>
                        <span>
                        It is a long established fact that a reader will be distracted by the
                        </span>
                     </div>
                  </div>
               </div>
               <div class="col-xl-6 col-12">
                  <div class="pt-subscribe-from">
                     <form id="form" class="form">
                        <div class="form-fields">
                           <input class="w-100" type="email" name="EMAIL" placeholder="Your email address" required=""/>
                           <input type="submit" value="Sign up"/>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </section> */}
            </>
      );
}
import React from "react";


export default function Header(){
      return(
            <>
             <header id="pt-header" class="pt-header-style-2 pt-has-sticky">
         <div class="row no-gutters">
            <div class="col-lg-2 col-md-4">
            <a class="navbar-brand" href="/">
               <img class="img-fluid logo" src="images/PryjInfraVission.jpg" alt="architeck"/> 
               </a>
            </div>
            <div class="col-lg-10">
               <div class="pt-top-header">
                  <div class="row no-gutters">
                     <div class="col-lg-10 col-xl-12">
                        <div class="pt-header-contact text-right">
                           <ul>
                              <li>
                                 <a href="tel:+91 5323586609"><i class="fas fa-phone"></i>
                                 <span>+91 5323586609</span>
                                 </a>
                              </li>
                              <li>
                                 <a href="mailto:info@prayagrajinfraengg.vapsoft.org"><i class="fas fa-envelope"></i><span>info@prayagrajinfraengg.vapsoft.org</span></a>
                              </li>
                              <li>
                                 <i class="far fa-clock"> Mon - Sat 10:00 - 16:00, Sun 09:00 - 12:00</i>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="pt-bottom-header">
                  <div class="row no-gutters">
                     <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg navbar-light">
                           <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                           <i class="fas fa-bars"></i>
                           </button>
                           <div class="collapse navbar-collapse" id="navbarSupportedContent">
                              <div id="pt-menu-contain" class="pt-menu-contain">
                                 <ul id="pt-main-menu" class="navbar-nav ml-auto">
                                    <li class="nav-item active">
                                       <a href="/" aria-current="page">Home</a>
                                    </li>
                                    <li class="menu-item">
                                       <a href="">About</a><i class="ion-chevron-down"></i>
                                       <ul class="sub-menu">
                                          <li class="menu-item"><a href="/about-us">About Us</a></li>
                                          <li class="menu-item"><a href="/our-mission">Mission & Vission</a></li>
                                          <li class="menu-item"><a href="/why-choose-us">Why Choose Us</a></li>
                                          <li class="menu-item"><a href="/our-team">Our Team</a></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <a href="#">Services</a><i class="ion-chevron-down"></i>
                                       <ul class="sub-menu">
                                          <li><a href="/thermal-power-project">Thermal Power Project</a></li>
                                          <li><a href="/captive-plant">Captive Power Plant</a></li>
                                          <li><a href="/overhauling">Overhauling</a></li>
                                          <li><a href="/supply-of-material">Supply of Material</a></li>
                                          <li><a href="/fabrication">Fabrication</a></li>
                                          <li><a href="/infrastructure">Infrastructure</a></li>
                                          <li><a href="/critical-piping">Critical Piping</a></li>
                                          <li><a href="/maintenance-power-projects">Maintenance of Power Projects</a></li>
                                       </ul>
                                    </li>
                                    <li>
                                       <a href="/project">Projects</a>
                                    </li>
                                    {/* <li>
                                       <a href="/">Career</a>
                                    </li>
                                    <li>
                                       <a href="/">Gallery</a>
                                    </li> */}
                                    <li>
                                       <a href="/contact-us">Contact Us</a>
                                    </li>
                                 </ul>
                              </div>
                           </div>
                           <div class="pt-header-info-box">
                              <div class="pt-menu-search-block">
                                 <a href="javascript:void(0)" id="pt-seacrh-btn"><i class="fa fa-search"></i></a>
                                 <div class="pt-search-form">
                                    <form role="search" method="get" class="search-form" action="/">
                                       <label>
                                       <span class="screen-reader-text">Search for:</span>
                                       <input type="search" class="search-field" placeholder="Search …" value="" name="s"/>
                                       </label>
                                       <button type="submit" class="search-submit"><span class="screen-reader-text">Search</span></button>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>
            </>
      );
}
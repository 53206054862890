import React from "react";

export default function Overhauling(){
return (
<div>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Overhauling</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Overhauling</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Gives The Best Engineering way</h2>
               <p class="pt-section-description">A machine overhaul is a general maintenance procedure conducted on a machine or other industrial equipment. The purpose of overhauling is to keep the system operational. Regular inspections can help to avoid all types of catastrophic damage. Machine overhaul is often done by organisations who provide maintenance services. Routine maintenance is normally planned once a year, and the frequency of overhauling can be agreed upon. For older machinery, and especially larger ones with complicated mechanics, a more frequent equipment inspection is suggested.
               <br/><br/>Nature begins to wear out machinery after many years of operation. This usual circumstance, however, might result in a major breakdown and a costly repair. What can be done to keep the machinery from breaking down and failing? The solution is to overhaul the machine.
</p>

            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Cost-effective</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Extended life length</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Increased performance</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Reduced labor costs</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">From the reseller's perspective, overhauling</span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/abhi_14.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
);
}
import React from "react";

export default function CaptivePowerPlant(){
return (
<div>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Captive Power Plant</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Captive Power Plant</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Brief About Captive Power Plant</h2>
               <p class="pt-section-description">The stability of grid power and the low quality of electricity in the industry have greatly hampered competition in today's market, forcing the industrial sector to shift to captive power generation. A captive power plant assists enterprises in generating power for their own use.The growing need for electricity in expanding global markets such as China, India, and the Middle East has fueled expansion in the captive power generating industry, which is used in a variety of businesses.<br/><br/>Given the increased need for this alternative source of energy, organisations such as ours offer comprehensive captive power solutions to sectors such as cement, textile, steel, paper, fertiliser, chemicals, food, and pharmaceuticals. Low cost is one of the advantages of constructing a captive power plant.Lowering electricity costs, lowering distribution and transmission losses, and increasing thermal efficiency are all advantages.</p>
            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Operation Program</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Quality Assurance</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">We provide World Class Quality in Work</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Safety</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Human Resources Development Services</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Major Objectivities considered in preparation of schedule</span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/abhi_13.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
);
}
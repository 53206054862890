import React from "react";

export default function ThermalPowerProject(){
return (
<div>
<section class="pt-breadcrumb">
   <div class="pt-bg-overley pt-opacity4" style={{backgroundImage: "url('images/bg/01.jpg')"}}></div>
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="pt-breadcrumb-title">Thermal Power Project</h1>
            <nav aria-label="breadcrumb">
               <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/"><i class="fas fa-home mr-2"></i>Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">Thermal Power Project</li>
               </ol>
            </nav>
         </div>
      </div>
   </div>
</section>
<section class="about-us">
   <div class="container">
      <div class="row d-flex align-items-center">
         <div class="col-lg-7 mb-5 text-xl-left text-md-left text-center">
            <div class="pt-section-title-box">
               <span class="pt-section-sub-title">Where Can We Help You</span>
               <h2 class="pt-section-title">Brief About Thermal Power Project</h2>
               <p class="pt-section-description">Thermal power plants are large-scale structures that generate electricity by converting heat energy. They are the backbone of global electrical generation, supplying over 60% of global power. Their reliance on fossil fuels, however, creates environmental issues.<br/>Thermal power plants operate on the premise of harnessing heat to generate high-pressure steam, which powers turbines attached to generators. The steam is then condensed and utilised as water.<br/>Thermal power plants, despite their dependability and efficiency, are a major source of air pollution, releasing sulphur dioxide, nitrogen oxides, particulate matter, and greenhouse gases such as carbon dioxide.<br/>Thermal power plants must adapt to lessen their environmental effect as the globe shifts to greener energy sources. This may be accomplished by switching to cleaner fuels such as natural gas.gas, as well as employing cutting-edge pollution-control systems.</p>

            </div>
            <ul class="icon-list-items  text-xl-left text-md-left text-center">
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Heat Generation: The procedure begins with significant heat generation.</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Water Boiling: This tremendous heat is then utilised to bring water to a boil in a high-pressure vessel. </span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Steam Turbine: High-pressure steam is sent to a steam turbine.</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Electricity Generation: The generator is where the magic happens. It generates power as it rotates.</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">Reusing Heat: However, the process does not finish there. After it has completed its task.</span>
               </li>
               <li class="icon-list-item">
                  <span class="icon-list-icon">
                  <i aria-hidden="true" class="fas fa-check"></i>
                  </span>
                  <span class="icon-list-text">6. Cooling and recycling: In the condenser, heated steam is cooled and converted back into water. </span>
               </li>
            </ul>
            <div class="pt-big-title text-left  wow animate__fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
               <h2 class="pt-big-title1">ABOUT ME</h2>
            </div>
         </div>
         <div class="col-lg-5">
            <div id="44c62" class="content__image-wrap content__image-wrap--half" data-color="#FF631B"
               data-direction="rl">
               <img class="img-fluid pt-images-1 w-100" src="images/about/abhi_12.jpg" alt="about-img"/>
               <div class="counter single-counter1 d-none d-sm-block">
                  <h5 class="timer" data-to="50" data-speed="5000">50</h5>
                  <span class="pt-counter-prefix" style={{marginTop: '-87px'}}>+</span>
                  <label>YEARS EXPERIENCE</label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</div>
);
}